import dashboard from './dashboard'

export default [
  ...dashboard,
  // {
  //   title: 'Documentation',
  //   route: 'second-page',
  //   icon: 'FileIcon',
  //   action: 'Browse',
  //   resource: 'users',
  // },
]
